import React, { useEffect, useState } from "react";
import Form from "../utils/ChargeForm";
import { CircularProgress, Stack, ThemeProvider, Typography, } from "@mui/material";
import { theme } from "../utils/theme";
import SnackbarComponent from "../utils/SnackBar";
const ProjectsUpdate = ({ $state, ChargeService, $translate }) => {
    const [charge, setCharge] = useState(); ///////////////////////////////////////////7
    const [loading, setLoading] = useState(true);
    const [lang, setLang] = useState(localStorage.getItem("lang"));
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    useEffect(() => {
        // Get project details
        const fetchProjects = async () => {
            try {
                const resource = await ChargeService.getCharge($state.params.id);
                setCharge(resource);
                setLoading(false);
            }
            catch (error) {
                console.error("Erro ao obter carregamento:", error.message);
                setSnackMessage("Erro ao obter carregamento");
                setOpenSnackbar(true);
                setLoading(false);
            }
        };
        if (loading) {
            fetchProjects();
        }
        const handleStorageChange = () => {
            setLang(localStorage.getItem("lang"));
        };
        const intervalId = setInterval(handleStorageChange, 500);
        return () => {
            clearInterval(intervalId);
        };
    }, [$state.params.id]);
    const closeSnackbar = () => {
        setOpenSnackbar(false);
    };
    if (loading) {
        return (<ThemeProvider theme={theme}>
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
          <CircularProgress color="primary"/>
        </Stack>
      </ThemeProvider>);
    }
    return (<ThemeProvider theme={theme}>
      <Stack spacing={2}>
        <Typography variant="h6">Editar Carregamento</Typography>
        <Typography variant="body2">
          {$translate.instant("TEXT_MANDATORY_FIELDS")}
        </Typography>
        <Form $state={$state} ChargeInfo={charge} ChargeService={ChargeService} $translate={$translate} type={"update"}></Form>
      </Stack>

      <SnackbarComponent message={snackMessage} open={openSnackbar} severity={"normal"} onClose={closeSnackbar}/>
    </ThemeProvider>);
};
export default ProjectsUpdate;
