import React, { useEffect, useState } from "react";
import { TextField, ThemeProvider, Card, Stack, Fab, Typography, Box, Tabs, Tab, } from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextEditor from "../utils/textEditor";
import { theme } from "../utils/theme";
import Icon from "@mdi/react";
import { mdiContentSave, mdiDelete } from "@mdi/js";
import "./../utils/utils.css";
import { useFormik } from "formik";
import * as yup from "yup";
import dayjs from "dayjs";
import SimpleModal from "./SimpleModal";
import SnackbarComponent from "./SnackBar";
import FileUpload from "./FileUpload";
import moment from "moment";
const Form = ({ $state, projectData, ProjectService, $translate, type, userData, }) => {
    const [lang, setLang] = useState(localStorage.getItem("lang"));
    const [editorText, setEditorText] = useState("");
    const [modalOpen, setModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [tabValue, setTabValue] = useState(0); // tabValue 0 -> PT | tabValue 1 -> EN
    const validationSchema = yup.object().shape({
        title: yup.lazy((value) => {
            if (tabValue === 0) {
                return yup.object().shape({
                    pt: yup.string().required("Insira o título."),
                });
            }
            else {
                return yup.object().shape({
                    en: yup.string().required("Enter the title."),
                });
            }
        }),
        budget: yup.string().min(0),
        financing: yup.string().min(0),
        investigator: yup.string().required(),
        beginDate: yup.date().required().min("2000-01-01", "Date is too early"),
        endDate: yup
            .date()
            .min(yup.ref("beginDate"), "End date can't be before the start date")
            .required(),
    });
    const formik = useFormik({
        initialValues: {
            id: type === "create" ? "" : projectData.id,
            image: {
                container: "",
                year: "",
                month: "",
                name: "",
            },
            title: {
                pt: type === "create" ? "" : projectData.title.pt,
                en: type === "create" ? "" : projectData.title.en,
            },
            reference: type === "create" ? "" : projectData.reference,
            investigator: type === "create" ? userData.name : projectData.investigator,
            description: {
                pt: type === "create" ? "" : projectData.description.pt,
                en: type === "create" ? "" : projectData.description.en,
            },
            partners: type === "create" ? "" : projectData.partners,
            entity: type === "create" ? "" : projectData.entity,
            budget: type === "create" ? 0 : projectData.budget,
            financing: type === "create" ? 0 : projectData.financing,
            beginDate: type === "create" ? moment(new Date()) : moment(projectData.beginDate),
            endDate: type === "create" ? moment("") : moment(projectData.endDate),
            website: type === "create" ? "" : projectData.website,
            creationDate: moment(new Date()),
            createdBy: {
                id: type === "create" ? "" : projectData.createdBy.id,
                name: type === "create" ? "" : projectData.createdBy.name,
                email: type === "create" ? "" : projectData.createdBy.email,
            },
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            //console.log(values);
            //submit();
            if (editorText !== "") {
                tabValue === 0
                    ? (values.description.pt = editorText)
                    : (values.description.en = editorText);
            }
            values.image = await saveImage();
            submit(values);
        },
    });
    const handleTab = (event, newValue) => {
        setTabValue(newValue);
    };
    const handleModal = (message) => {
        setModalOpen(true);
        setModalMessage(message);
    };
    // if modal OK (result true) delete project
    const handleModalClose = async (result) => {
        setModalOpen(false);
        if (result) {
            try {
                if (projectData.image.name !== "") {
                    await deleteImage(projectData.image);
                }
                await ProjectService.deleteProject(projectData);
                goToProjectsList("Projeto eliminado!");
            }
            catch (error) {
                console.error("Erro ao eliminar projeto:", error);
                setSnackMessage("Erro ao eliminar projeto");
                setOpenSnackbar(true);
            }
        }
    };
    const handleFileSelected = async (file) => {
        if (file === null) {
            return;
        }
        setSelectedFile(file);
    };
    // show message received in the snackbar -> go to projects list
    const goToProjectsList = (message) => {
        setSnackMessage(message);
        setOpenSnackbar(true);
        setTimeout(() => $state.go("app.projects.list"), 2000);
    };
    const closeSnackbar = () => {
        setOpenSnackbar(false);
    };
    useEffect(() => {
        // Handle language
        const handleStorageChange = () => {
            setLang(localStorage.getItem("lang"));
        };
        const intervalId = setInterval(handleStorageChange, 500);
        return () => {
            clearInterval(intervalId);
        };
    }, []);
    const saveImage = async () => {
        const formData = new FormData();
        formData.append("file", selectedFile);
        try {
            const response = await fetch("/api/assets/upload/projects", {
                method: "POST",
                body: formData,
            });
            if (response.ok) {
                console.log("File uploaded successfully");
                const content = await response.json();
                return content.result;
            }
            else {
                console.error("Failed to upload file");
                return {
                    container: "",
                    year: "",
                    month: "",
                    name: "",
                };
            }
        }
        catch (error) {
            console.error("Error uploading file:", error);
        }
    };
    const deleteImage = async (image) => {
        try {
            const response = await fetch(`/api/assets/upload/${image.container}/${image.year}/${image.month}/${image.name}`, {
                method: "DELETE",
            });
            if (response.ok) {
                console.log("Imagem eliminada com sucesso");
                return true;
            }
            else {
                console.error("Falha ao eliminar imagem");
                return false;
            }
        }
        catch (error) {
            console.error("Erro ao eliminar imagem:", error);
            return false;
        }
    };
    const submit = async (projectInfo) => {
        try {
            if (type === "create") {
                projectInfo.createdBy = userData;
                delete projectInfo.id;
                await ProjectService.createProject(projectInfo);
                goToProjectsList("Projeto adicionado!");
            }
            else {
                projectInfo.id = projectData.id;
                await ProjectService.updateProject(projectInfo);
                goToProjectsList("Projeto editado com sucesso!");
            }
        }
        catch (error) {
            if (type === "create") {
                console.error("Erro ao criar projeto:", error);
                setSnackMessage("Erro ao criar projeto");
                setOpenSnackbar(true);
            }
            else {
                console.error("Erro ao editar projeto:", error);
                setSnackMessage("Erro ao editar projeto");
                setOpenSnackbar(true);
            }
        }
    };
    const handleTextEditorChange = (text) => {
        setEditorText(text);
    };
    const handleTextInput = (event) => {
        const { id, value } = event.target;
        formik.setFieldValue(id, value);
    };
    return (<ThemeProvider theme={theme}>
      <form onSubmit={formik.handleSubmit}>
        <Stack direction="column" spacing={2}>
          <Card sx={{ padding: "10px", borderRadius: 3 }}>
            <Box sx={{ marginBottom: 3 }}>
              <Tabs value={tabValue} onChange={handleTab} aria-label="basic tabs example">
                <Tab label={<Typography variant="subtitle2">
                      PORTUGUÊS <span className="flag-icon flag-icon-pt"></span>
                    </Typography>}/>
                <Tab label={<Typography variant="subtitle2">
                      ENGLISH <span className="flag-icon flag-icon-gb"></span>
                    </Typography>}/>
              </Tabs>
            </Box>

            <Stack direction="column" spacing={2}>
              <TextField id={`title.${tabValue === 0 ? "pt" : "en"}`} label={`${$translate.instant("INPUT_TITLE")}`} error={Boolean(formik.errors.title)} value={tabValue === 0
            ? formik.values.title.pt
            : formik.values.title.en} onBlur={formik.handleBlur} onChange={handleTextInput} variant="standard" required/>

              <TextField id="reference" label={`${$translate.instant("TEXT_REFERENCE")}`} onChange={handleTextInput} variant="standard" value={formik.values.reference}/>
              <TextField id="investigator" label={`${$translate.instant("TEXT_INVESTIGATOR")}`} error={Boolean(formik.errors.investigator)} value={formik.values.investigator} onBlur={formik.handleBlur} onChange={handleTextInput} variant="standard" required/>
              <TextField id="partners" label={`${$translate.instant("TEXT_PARTNERS")}`} onChange={handleTextInput} variant="standard" value={formik.values.partners}/>
              <TextField id="entity" label={`${$translate.instant("TEXT_ENTITY")}`} onChange={handleTextInput} variant="standard" value={formik.values.entity}/>
              <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
                <Stack direction="row" justifyContent="center" alignItems="center" spacing={4}>
                  <TextField id="budget" label={`${$translate.instant("TEXT_BUDGET")}`} type="text" onChange={handleTextInput} variant="outlined" value={formik.values.budget} error={Boolean(formik.errors.budget)}/>
                  <TextField id="financing" label={`${$translate.instant("TEXT_FINANCING")}`} type="text" onChange={handleTextInput} variant="outlined" value={formik.values.financing} error={Boolean(formik.errors.financing)}/>
                </Stack>
              </Stack>
              <Stack justifyContent="center" alignItems="center">
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker label={`${$translate.instant("TEXT_BEGIN")}`} sx={{ marginBottom: 10 }} value={formik.values.beginDate} onChange={(value) => formik.setFieldValue("beginDate", value)} slotProps={{
            textField: {
                required: true,
                error: Boolean(formik.errors.beginDate),
            },
        }}/>
                    <DatePicker label={`${$translate.instant("TEXT_END")}`} sx={{ marginBottom: 10 }} value={formik.values.endDate} onChange={(value) => formik.setFieldValue("endDate", value || dayjs())} slotProps={{
            textField: {
                required: true,
                error: Boolean(formik.errors.endDate),
            },
        }}/>
                  </DemoContainer>
                </LocalizationProvider>
              </Stack>
            </Stack>
          </Card>
          <Card sx={{ padding: "10px", borderRadius: 3 }}>
            <TextEditor translate={$translate} onTextChange={handleTextEditorChange} text={tabValue === 0
            ? formik.values.description.pt
            : formik.values.description.en}/>
          </Card>

          <Card sx={{ padding: "10px", borderRadius: 3, textAlign: "center" }}>
            <Stack spacing={2}>
              <TextField fullWidth id="website" label="Website" variant="standard" onChange={handleTextInput} value={formik.values.website}/>
              <Stack justifyContent="flex-start" alignItems="flex-start">
                <Typography variant="subtitle1" color="#A9A9A9">
                  Logo
                </Typography>
              </Stack>
              <FileUpload onFileSelect={handleFileSelected}/>
            </Stack>
          </Card>
        </Stack>
      </form>

      <Box className="fabButtonPosition">
        {projectData === undefined ? (<Box></Box>) : (<Fab color="primary" aria-label="add" sx={{ position: "absolute" }} onClick={() => handleModal("Deseja remover o projeto?")}>
            <Icon path={mdiDelete} size={1}/>
          </Fab>)}
      </Box>
      <Box className={projectData === undefined ? "fabButtonPosition" : "fabButtonPosition2"}>
        <Fab color="primary" aria-label="add" sx={{ position: "absolute", backgroundColor: "#db4437" }} onClick={() => formik.handleSubmit()}>
          <Icon path={mdiContentSave} size={1}/>
        </Fab>
      </Box>

      <SimpleModal open={modalOpen} onClose={handleModalClose} message={modalMessage}/>

      <SnackbarComponent message={snackMessage} open={openSnackbar} severity={"normal"} onClose={closeSnackbar}/>
    </ThemeProvider>);
};
export default Form;
