export default class AccessApprovalController {
  constructor($state, AccessService, UIService) {
    this.$state = $state;
    this.AccessService = AccessService;
    this.UI = UIService;

    this.loadData();
  };

  loadData = () => {
    this.loaded = false;
    this.AccessService.my().then(list => {
      this.data = list;
      this.loaded = true;
    });
  };

  approved = (request) => {
    this.AccessService.setState(request.id, 1).then(() => {
      this.sendEmail('acessos@deec.uc.pt', request, true);  // send email to inform request was aproved
      this.UI.addToast('Pedido Aprovado');
      this.loadData();
    });
  };

  rejected = (request) => {
    this.AccessService.setState(request.id,  2).then(() => {
      this.sendEmail(request.applicantMail, request, false); // send email to inform request was rejected
      this.UI.addToast('Pedido Rejeitado');
      this.loadData();
    });
  };

  sendEmail = (email, data, approved) => {
    let details = {
      emailAddress: email,
      applicantNumber: data.applicantNumber,
      applicantName: data.applicantName,
      applicantMail: data.applicantMail,
      responsibleEntityMail: data.responsibleEntityMail,
      dateStart: moment(data.dateStart).format("DD/MM/YYYY"),
      dateEnd: moment(data.dateEnd).format("DD/MM/YYYY"),
      reason: data.reason,
      observations: data.observations,
      requestType: data.requestType,
      spaces: this.AccessService.processSpaces2(data)
    };
    if (approved) {
      details.subject = 'my.DEEC | Pedido de Acesso Aprovado';
      details.emailModel = 'PedidoAprovado';
      details.greeting = 'Foi aprovado o seguinte pedido de acesso ao DEEC:';
      details.introText = 'Aceda à plataforma my.DEEC para mais informação:';
      details.goToUrl = '/access/requests-access';
      details.buttonText = 'ACESSOS APROVADOS';
    } else {
      details.subject = 'my.deec.uc | Pedido de Acesso Rejeitado';
      details.emailModel = 'PedidoRejeitado';
      details.greeting = 'Foi rejeitado o seguinte pedido de acesso ao DEEC:';
      details.introText = 'Em caso de dúvida, consulte o seu responsável.';
    }
    this.AccessService.email(details).then(() => {
      // Do nothing here
    }).catch(err => {
      console.log(err);
      this.UI.addToast("Erro no envio de Email");
    });
  };
}
AccessApprovalController.$inject = ['$state', 'AccessService', 'UIService'];
