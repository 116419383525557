import React from "react";
import ReactHtmlParser from "react-html-parser";
import { Dialog, Box, DialogContent, Stack, Typography, DialogTitle, useMediaQuery, useTheme, } from "@mui/material";
export default function ViewDialog({ title, body, image, open, onClose, }) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    return (<Dialog open={open} onClose={onClose} maxWidth={"lg"} fullWidth={true}>
      <DialogTitle sx={{ position: "absolute", top: 0 }}></DialogTitle>
      <DialogContent sx={{ p: 0, aspectRatio: 1 }} onClick={onClose}>
        <Stack direction="column" alignItems='center' justifyContent='center' sx={{ height: 1, width: 1 }}>
          <Box sx={{
            width: 1,
            height: 1,
            background: image
                ? `url(${image}) center center no-repeat`
                : 'url(/assets/images/default.jpg) center center no-repeat',
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            bgcolor: "black",
        }}></Box>
          <Stack direction="row" justifyContent="space-between" alignItems="flex-start" gap={2} sx={{
            width: 1,
            p: 2,
            color: "white",
            background: "rgba(0, 0, 0, 0.6)",
            position: "relative",
        }}>
            <Box sx={{
            flexShrink: 1,
            alignSelf: { xs: "flex-start", sm: "center" },
        }}>
              <Typography fontWeight="bold">{title}</Typography>
              <Typography variant="body2">{ReactHtmlParser(body)}</Typography>
            </Box>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>);
}
