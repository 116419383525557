export default class DissertationDetailsController {
  constructor($state, $scope, $translate, DissertationService, UIService, AuthorizationService) {
    this.$state = $state;
    this.$translate = $translate;
    this.DissertationService = DissertationService;
    this.AuthorizationService = AuthorizationService;
    this.UI = UIService;
    $scope.$watch(() => {
      return localStorage.getItem("lang")
    }, (val) => {
      this.lang = val;
    }, true);
    this.loadData();
  }

  loadData = () => {
    this.loaded = false;
    this.DissertationService.get(this.$state.params.id).then(r => {
      this.data = r;
      this.loaded = true;
    }).catch(e => {
      this.$state.go('app.dissertations.list');
    });
  };

  fork = () => {
    this.UI.showConfirm(this.$translate.instant("TEXT_DIS_FORK")).then(r => {
      if (r) {
        this.DissertationService.fork(this.data).then(d => {
          this.UI.addSnackbar(this.$translate.instant("TEXT_DIS_FORKED"), this.$translate.instant("BUTTON_VIEW"), true).then(a => {
            if (a) {
              this.$state.go('app.dissertations.details', {
                id: d.id
              });
            }
          });
        })
      }
    }).catch(() => { });
  }

  isOwner = () => {
    if (!this.loaded) {
      return false;
    }
    return this.DissertationService.isOwner(this.data) || this.AuthorizationService.canPerform(['cn=Informatica,cn=groups,dc=deec,dc=uc,dc=pt']);
  };

  apply = () => {
    this.UI.showConfirm(this.$translate.instant("TEXT_APPLY_TO")).then(r => {
      this.loaded = false;
      if (r) {
        this.DissertationService.application(this.data.id).then(result => {
          if (result) {
            this.UI.addToast(this.$translate.instant("TEXT_APPLICATION_SUCCESS"));
            this.loadData();
          } else {
            this.UI.addToast(this.$translate.instant("TEXT_APPLICATION_FAILED"));
            this.loaded = true;
          }
        });
      }
    });
  };

  assign = () => {
    if (!this.isOwner()) {
      this.UI.addToast(this.$translate.instant("TEXT_NO_PERMISSIONS"));
    } else {
      if (this.data.student) {
        this.UI.addToast(this.$translate.instant("TEXT_ALREADY_ASSIGNED"));
      } else {
        this.zzb();
      }
    }
  }

  zzb = (student) => {
    this.UI.showConfirm(this.$translate.instant("TEXT_ASSIGN_STUDENT")).then(r => {
      if (r) {
        student.assigned = new Date();
        this.data.student = student;
        this.DissertationService.save(this.data).then(r => {
          this.UI.addToast(this.$translate.instant("TEXT_ASSIGNED_STUDENT"));
          this.loadData();
        }).catch(() => {
          this.loadData();
          this.UI.addToast(this.$translate.instant("TEXT_ASSIGN_STUDENT_FAILED"));
        })
      }
    })
  }

  close = () => {
    if (!this.isOwner()) {
      this.UI.addToast(this.$translate.instant("TEXT_NO_PERMISSIONS"));
    } else {
      if (this.data.state !== 0) {
        this.UI.showConfirm(this.$translate.instant("TEXT_DIS_EXPLAIN_CLOSE")).then(r => {
          if (r) {
            this.DissertationService.close(this.data).then(r => {
              this.UI.addToast(this.$translate.instant("TEXT_DIS_CLOSED"));
              this.loadData();
            });
          }
        }).catch(() => { });
      } else {
        this.UI.addToast(this.$translate.instant("TEXT_DIS_CANNOT_CLOSE"));
      }
    }
  }

  hide = () => {
    if (!this.isOwner()) {
      this.UI.addToast(this.$translate.instant("TEXT_NO_PERMISSIONS"));
    } else {
      if (this.data.state === 1 && !this.data.student) {
        this.DissertationService.hide(this.data).then(r => {
          this.UI.addToast(this.$translate.instant("TEXT_DIS_HIDDEN"));
          this.loadData();
        });
      } else {
        this.UI.addToast(this.$translate.instant("TEXT_DIS_CANNOT_HIDE"));
      }
    }
  }

  show = () => {
    if (!this.isOwner()) {
      this.UI.addToast(this.$translate.instant("TEXT_NO_PERMISSIONS"));
    } else {
      if (this.data.state === 2) {
        this.DissertationService.show(this.data).then(r => {
          this.UI.addToast(this.$translate.instant("TEXT_DIS_VISIBLE"));
          this.loadData();
        });
      }
    }
  }

  canApply = () => {
    if (this.loaded) {
      return this.DissertationService.canApply(this.data);
    }
    return false;
  }

  delete = () => {
    if (!this.isOwner()) {
      this.UI.addToast(this.$translate.instant("TEXT_NO_PERMISSIONS"));
    } else {
      if (this.data.state <= 0) {
        this.UI.showConfirm(this.$translate.instant("TEXT_CONFIRM_DELETE")).then(r => {
          this.DissertationService.delete(this.data).then(() => {
            this.$state.go('app.dissertations.own');
            this.UI.addToast(this.$translate.instant("TEXT_DELETED"));
          }).catch(e => {
            this.$state.go('app.dissertations.own');
            this.UI.addToast(this.$translate.instant("TEXT_FAILED_DELETED"));
          });
        });
      }
    }
  }
}

DissertationDetailsController.$inject = ['$state', '$scope', '$translate', 'DissertationService', 'UIService', 'AuthorizationService'];
