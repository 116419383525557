export default class PublicAccessFormController {
  constructor($translate, $state, AccessService, UIService, PersonService) {
    this.$translate = $translate;
    this.$state = $state;
    this.AccessService = AccessService;
    this.UI = UIService;
    this.PersonService = PersonService;
    this.completed = false;

    this.data = {
      applicantNumber: undefined,
      applicantName: '',
      applicantMail: '',
      responsibleEntityMail: '',
      dateStart: undefined,
      dateEnd: undefined,
      reason: '',
      observations: '',
      state: 0,
      requestType: ''
    };

    this.usableSpaces = this.AccessService.getUsableSpaces();

    //Type of request
    this.type = [
      "Aluno de Doutoramento",
      "Aluno de Dissertação & Mestrado",
      "Investigador IT Coimbra",
      "Investigador ISR Coimbra",
      "Investigador INESC Coimbra",
      "Investigador CEMMPRE"
    ];

    // Details for email and table after form
    this.details = {};

    // Find information for responsable
    this.loading = true;
    this.availableResponsables = [];
    this.auxResponsavel = undefined;
    this.loadData();
  }

  // Find Docentes, Não Docentes for responsable choosing
  loadData = () => {
    let wait = this.UI.showWaiting();
    this.PersonService.teachers().then(teachers => {
      this.availableResponsables = teachers;
      this.PersonService.staffs().then(staffs => {
        wait.close();
        this.availableResponsables = this.availableResponsables.concat(staffs);
      }).catch((error) => {
        console.log(error);
        wait.close();
        let errorMessage = this.$translate.instant("TEXT_FORM_ACCESS_NAO_DOCENTES_ERROR");
        alert = this.UI.showAlert(errorMessage);
        alert.finally(() => {
          // Do nothing right now.
        }).catch(() => {});
      });
    }).catch((error) => {
      console.log(error);
      wait.close();
      let errorMessage = this.$translate.instant("TEXT_FORM_ACCESS_DOCENTES_ERROR");
      alert = this.UI.showAlert(errorMessage);
      alert.finally(() => {
        // Do nothing right now.
      }).catch(() => {});
    });
  };

  addRequest = () => {
    // Check if Responsable is filled
    if (!this.auxResponsavel || !this.auxResponsavel.email) {
      let errorMessage = this.$translate.instant("TEXT_FORM_ACCESS_MISSING_RESPONSABLE");
      alert = this.UI.showAlert(errorMessage);
      alert.finally(() => {
        // Do nothing right now.
      }).catch(() => {});
      return;
    }
    // Save responsibleEntityMail
    this.data.responsibleEntityMail = this.auxResponsavel.email;

    //Save Spaces
    this.data.spacesAccess = [];
    for (let i = 0; i < this.usableSpaces.length; i++) {
      if (this.usableSpaces[i].selected)
        this.data.spacesAccess.push(this.usableSpaces[i].designacao);
    }

    // Check if no spaces were selected
    if (this.data.spacesAccess.length === 0) {
      let errorMessage = this.$translate.instant("TEXT_FORM_ACCESS_MISSING_SPACES");
      alert = this.UI.showAlert(errorMessage);
      alert.finally(() => {
        // Do nothing right now.
      }).catch(() => {});
      return;
    }

    //Save data
    this.AccessService.save(this.data).then(() => {
      this.sendEmail(this.data);
    }).catch(error => {
      console.log(error);
      let errorMessage = this.$translate.instant("TEXT_FORM_ACCESS_ERROR");
      alert = this.UI.showAlert(errorMessage);
      alert.finally(() => {
        // Do nothing right now.
      }).catch(() => {});
    });
  }

  sendEmail = (data) => {
    this.details = {
      emailAddress: data.responsibleEntityMail,
      subject: 'my.DEEC | Pedido de Acesso',
      emailModel: 'PedidoAcesso',
      greeting: 'Recebeu um pedido de acesso ao DEEC:',
      introText: 'Aceda à plataforma my.DEEC pelo botão seguinte para o avaliar.',
      goToUrl: '/access/approval-access',
      buttonText: 'VER PEDIDOS DE ACESSO',
      applicantNumber: data.applicantNumber,
      applicantName: data.applicantName,
      applicantMail: data.applicantMail,
      responsibleEntityMail: data.responsibleEntityMail, // Only used for
      dateStart: moment(data.dateStart).format("DD/MM/YYYY"),
      dateEnd: moment(data.dateEnd).format("DD/MM/YYYY"),
      reason: data.reason,
      observations: data.observations,
      requestType: data.requestType,
      spaces: data.spacesAccess
    };
    this.AccessService.email(this.details).then(() => {
      this.completed = true;
    }).catch(err => {
      // Email wasn't sent, but request was registered, so for the user it is not their concern
      this.completed = true;
    });
  };

  goToAnother = () => {
    this.$state.go("public.accessform", {}, {reload: true});
  };

}
PublicAccessFormController.$inject = ['$translate', '$state', 'AccessService', 'UIService', 'PersonService'];
