import React, { useEffect, useState } from "react";
import { ThemeProvider, Card, Stack, Typography, Box, Fab, CircularProgress, Link, } from "@mui/material";
import { theme } from "../utils/theme";
import "./../utils/utils.css";
import Icon from "@mdi/react";
import { mdiPencil } from "@mdi/js";
import SnackbarComponent from "../utils/SnackBar";
import moment from "moment";
const ProjectsDetails = ({ $state, ProjectService, $translate }) => {
    const [project, setProject] = useState({
        title: { pt: "", en: "" },
        reference: "",
        investigator: "",
        description: { pt: "", en: "" },
        partners: "",
        entity: "",
        budget: 0,
        financing: 0,
        beginDate: moment(),
        endDate: moment(),
        website: "",
        id: "",
        image: {
            container: "",
            year: "",
            month: "",
            name: "",
        },
        creationDate: moment(),
        createdBy: { id: "", name: "", email: "" },
    });
    const [lang, setLang] = useState(localStorage.getItem("lang"));
    const [loading, setLoading] = useState(true);
    const [userId, setUserId] = useState("");
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    useEffect(() => {
        // Get project details
        const fetchProjects = async () => {
            try {
                const resource = await ProjectService.getProject($state.params.id);
                const projectData = {
                    title: resource.title,
                    reference: resource.reference,
                    investigator: resource.investigator,
                    description: resource.description,
                    partners: resource.partners,
                    entity: resource.entity,
                    budget: resource.budget,
                    financing: resource.financing,
                    beginDate: resource.beginDate,
                    endDate: resource.endDate,
                    image: {
                        container: resource.image.container,
                        year: resource.image.year || "",
                        month: resource.image.month || "",
                        name: resource.image.name,
                    },
                    website: resource.website,
                    id: resource.id,
                    creationDate: resource.creationDate,
                    createdBy: resource.createdBy === undefined
                        ? { id: "", name: "", email: "" }
                        : resource.createdBy,
                };
                setProject(projectData);
                setLoading(false);
            }
            catch (error) {
                console.error("Erro ao carregar projeto:", error.message);
                setSnackMessage("Erro ao carregar projeto");
                setOpenSnackbar(true);
                setLoading(false);
            }
        };
        if (project.investigator === "") {
            fetchProjects();
            getUser();
        }
        // Handle Language
        const handleStorageChange = (event) => {
            setLang(localStorage.getItem("lang"));
        };
        const intervalId = setInterval(handleStorageChange, 500);
        return () => {
            clearInterval(intervalId);
        };
    }, []);
    const getUser = async () => {
        try {
            const userData = await ProjectService.getLoggedUser();
            setUserId(userData.id);
        }
        catch (error) {
            console.error("Erro ao obter utilizador:", error);
            setSnackMessage("Erro ao obter utilizador");
            setOpenSnackbar(true);
            setLoading(false);
        }
    };
    const closeSnackbar = () => {
        setOpenSnackbar(false);
    };
    /// get image-> /api/upload/projects/download/
    return (<ThemeProvider theme={theme}>
      {loading ? (<Box sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
            }}>
          <CircularProgress color="primary"/>
        </Box>) : (<Box>
          <Stack spacing={2}>
            <Card sx={{ padding: "10px", borderRadius: 3 }}>
              <Stack spacing={2}>
                <Stack spacing={2}>
                  <Typography variant="h5">
                    {project.title.pt !== ""
                ? project.title.pt
                : project.title.en}
                  </Typography>
                  <Stack spacing={0}>
                    <Typography variant="h6">
                      {`${$translate.instant("TEXT_INVESTIGATOR")}`}
                    </Typography>
                    <Typography variant="subtitle1">
                      {project.investigator}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack spacing={0}>
                  <Typography variant="h6">{`${$translate.instant("INPUT_DESCRIPTION")}`}</Typography>
                  <Box sx={{ whiteSpace: "pre-wrap" }}>
                    {project.description.pt !== ""
                ? project.description.pt
                : project.description.en}
                  </Box>
                </Stack>
                {project.partners === "" ? (<Box></Box>) : (<Stack spacing={0}>
                    <Typography variant="h6">
                      {`${$translate.instant("TEXT_PARTNERS")}`}
                    </Typography>
                    <Typography variant="subtitle1">
                      {project.partners}
                    </Typography>
                  </Stack>)}

                {project.reference === "" ? (<Box></Box>) : (<Stack spacing={0}>
                    <Typography variant="h6">
                      {`${$translate.instant("TEXT_REFERENCE")}`}
                    </Typography>
                    <Typography variant="subtitle1">
                      {project.reference}
                    </Typography>
                  </Stack>)}

                <Stack direction="column" spacing={0}>
                  <Typography variant="body1">
                    {`${$translate.instant("TEXT_BEGIN")}`}:{" "}
                    {project.beginDate.toString().slice(0, 10)}
                  </Typography>
                  <Typography variant="body1">
                    {`${$translate.instant("TEXT_END")}`}:{" "}
                    {project.endDate.toString().slice(0, 10)}
                  </Typography>
                </Stack>
              </Stack>
            </Card>
            {project.budget === 0 && project.financing === 0 ? (<Box></Box>) : (<Card sx={{ padding: "10px", borderRadius: 3 }}>
                <Stack spacing={0}>
                  <Stack spacing={0}>
                    <Typography variant="h6">
                      {`${$translate.instant("TEXT_BUDGET")}`}
                    </Typography>
                    <Typography variant="subtitle1">
                      {project.budget}
                    </Typography>
                  </Stack>
                  <Stack spacing={0}>
                    <Typography variant="h6">
                      {`${$translate.instant("TEXT_FINANCING")}`}
                    </Typography>
                    <Typography variant="subtitle1">
                      {project.financing}
                    </Typography>
                  </Stack>
                </Stack>
                {project.website === "" ? (<Box></Box>) : (<Stack spacing={0}>
                    <Typography variant="h6">Website</Typography>
                    <Link href={project.website}>{project.website}</Link>
                  </Stack>)}
              </Card>)}

            {project.image.name === "" ? (<Box></Box>) : (<Card sx={{ padding: "10px", borderRadius: 3 }}>
                <Stack spacing={1}>
                  <Typography variant="h6">Logo</Typography>
                  <Stack justifyContent="center" alignItems="center" spacing={2}>
                    <Box component="img" src={`/api/assets/download/${project.image.container}/${project.image.year}/${project.image.month}/${project.image.name}`} /// `/api/assets/download/${item.image.container}/${item.image.year}/${item.image.month}/${item.image.name}`
             alt="Uploaded" 
            //style={{ maxWidth: "50%", marginTop: "10px" }}
            sx={{
                    maxWidth: "500px",
                    maxHeight: "500px",
                    width: "auto",
                    height: "auto",
                }}></Box>
                  </Stack>
                </Stack>
              </Card>)}
          </Stack>
          <Box>
            {project.createdBy.id === userId ? (<Box className="fabButtonPosition">
                <Fab color="primary" aria-label="add" sx={{ position: "absolute", backgroundColor: "#000000" }} onClick={() => $state.go("app.projects.update", { id: project.id })}>
                  <Icon path={mdiPencil} size={1}/>
                </Fab>
              </Box>) : (<Box></Box>)}
          </Box>
        </Box>)}
      <SnackbarComponent message={snackMessage} open={openSnackbar} severity={"normal"} onClose={closeSnackbar}/>
    </ThemeProvider>);
};
export default ProjectsDetails;
