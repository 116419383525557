export default class DissertationsEditController {
  constructor(
    $state,
    $scope,
    $translate,
    UIService,
    AuthenticationService,
    DissertationService,
    PersonService
  ) {
    this.Dissertation = DissertationService;
    this.Person = PersonService;
    this.Auth = AuthenticationService;
    this.$translate = $translate;
    this.$state = $state;
    this.UI = UIService;
    this.semesters = [
      /*{
      id: 0,
      title: "Ambos os semestres"
    },*/
      {
        id: 1,
        title: "1º semestre",
      },
      {
        id: 2,
        title: "2º semestre",
      },
    ];
    $scope.$watch(
      () => {
        return localStorage.getItem("lang");
      },
      (val) => {
        this.lang = val;
      },
      true
    );

    if (!$state.params.id) {
      this.UI.addToast(this.$translate.instant("TEXT_DIS_NOT_FOUND"));
      $state.go("app.dissertations.own");
    } else {
      this.loadData();
    }

    let year = moment().format("YYYY");
    this.years = [];
    year = Number(year);
    for (let i = -4; i < 4; i++) {
      this.years.push(`${year + i}/${year + i + 1}`);
    }
  }

  save = () => {
    this.Dissertation.save(this.data)
      .then((r) => {
        this.UI.addToast(this.$translate.instant("TEXT_DIS_SAVED"));
        this.$state.go("app.dissertations.own");
      })
      .catch((data) => {
        this.UI.showDialog({
          size: "lg",
          template: require("./../dialogs/message.html"),
          controller: [
            "$scope",
            ($scope) => {
              $scope.messages = data;

              $scope.cancel = () => {
                $scope.$close();
              };
            },
          ],
        });
      });
  };

  loadData = () => {
    this.loaded = false;
    this.Person.teachers()
      .then((teachers) => {
        this.teachers = teachers;
        this.Person.students()
          .then((students) => {
            this.students = students;
            this.Dissertation.specializations()
              .then((specializations) => {
                this.specializations = specializations;
                this.Dissertation.get(this.$state.params.id)
                  .then((r) => {
                    this.loaded = true;
                    this.data = r;
                  })
                  .catch(() => {
                    this.UI.addToast(
                      this.$translate.instant("TEXT_DIS_NOT_FOUND")
                    );
                    this.$state.go("app.dissertations.list");
                  });
              })
              .catch(() => {});
          })
          .catch(() => {});
      })
      .catch(() => {});
  };

  student = () => {
    if (this.data.student) {
      let translation = this.$translate.instant("TEXT_DIS_ALREADY_ASSIGNED");
      this.UI.showConfirm(translation).then((r) => {
        if (r) {
          this.data.student = undefined;
          this.zza();
        }
      });
    } else {
      this.zza();
    }
  };

  zza = () => {
    this.UI.showDialog({
      size: "lg",
      template: require("./../dialogs/student.html"),
      controller: [
        "$scope",
        "$filter",
        ($scope, $filter) => {
          $scope.select = (user) => {
            user.oldIdx = $scope.available.indexOf(user);
            $scope.selected = user;
            $scope.available.splice(user.oldIdx, 1);
          };

          $scope.pop = () => {
            let user = angular.copy($scope.selected);
            $scope.selected = undefined;
            $scope.available.splice(user.oldIdx, 0, user);
          };

          $scope.ok = () => {
            $scope.$close($scope.selected);
          };

          $scope.cancel = () => {
            $scope.$dismiss();
          };

          $scope.available = this.students;
          $scope.selected = undefined;
          $scope.filter = "";
          $scope.pagination = {
            perPage: 10,
            current: 0,
            previous: () => {
              if ($scope.pagination.current > 0) $scope.pagination.current--;
            },
            onFilter: () => {
              const filteredArray = $filter("filter")(
                $scope.available,
                $scope.filter
              );
              const afterFilter =
                Math.ceil(filteredArray.length / $scope.pagination.perPage) - 1;
              if (afterFilter < $scope.pagination.current) {
                $scope.pagination.current = afterFilter;
              }
            },
            next: () => {
              if ($scope.pagination.current < $scope.pagination.total() - 1)
                $scope.pagination.current++;
            },
            total: () => {
              const filteredArray = $filter("filter")(
                $scope.available,
                $scope.filter
              );
              return Math.ceil(
                filteredArray.length / $scope.pagination.perPage
              );
            },
          };
        },
      ],
    }).then((r) => {
      delete r.oldIdx;
      r.assigned = new Date();
      this.data.student = r;
    });
  };

  internal = () => {
    let teachers = angular.copy(this.teachers);
    teachers = _.differenceBy(teachers, this.data.internal, "id");
    var removeIndex = teachers
      .map(function (item) {
        return item.id;
      })
      .indexOf(this.Auth.getId());
    ~removeIndex && teachers.splice(removeIndex, 1);
    this.UI.showDialog({
      size: "lg",
      template: require("./../dialogs/supervisors/internal.html"),
      controller: [
        "$scope",
        "$filter",
        ($scope, $filter) => {
          $scope.select = (user) => {
            user.oldIdx = $scope.available.indexOf(user);
            $scope.selected.push(user);
            $scope.available.splice(user.oldIdx, 1);
          };

          $scope.pop = (user) => {
            $scope.selected.splice($scope.selected.indexOf(user), 1);
            $scope.available.splice(user.oldIdx, 0, user);
          };

          $scope.ok = () => {
            $scope.$close($scope.selected);
          };

          $scope.cancel = () => {
            $scope.$dismiss();
          };

          $scope.available = teachers;
          $scope.selected = [];
          $scope.filter = "";
          $scope.pagination = {
            perPage: 10,
            current: 0,
            previous: () => {
              if ($scope.pagination.current > 0) $scope.pagination.current--;
            },
            onFilter: () => {
              const filteredArray = $filter("filter")(
                $scope.available,
                $scope.filter
              );
              const afterFilter =
                Math.ceil(filteredArray.length / $scope.pagination.perPage) - 1;
              if (afterFilter < $scope.pagination.current) {
                $scope.pagination.current = afterFilter;
              }
            },
            next: () => {
              if ($scope.pagination.current < $scope.pagination.total() - 1)
                $scope.pagination.current++;
            },
            total: () => {
              const filteredArray = $filter("filter")(
                $scope.available,
                $scope.filter
              );
              return Math.ceil(
                filteredArray.length / $scope.pagination.perPage
              );
            },
          };
        },
      ],
    }).then((res) => {
      res.forEach((r) => {
        delete r.oldIdx;
      });
      this.data.internal = this.data.internal || [];
      this.data.internal.push(...res);
    });
  };

  removeInternal = (obj) => {
    this.data.internal.splice(obj);
  };

  removeExternal = (obj) => {
    this.data.external.splice(obj);
  };

  external = () => {
    this.UI.showDialog({
      size: "lg",
      template: require("./../dialogs/supervisors/external.html"),
      controller: [
        "$scope",
        ($scope) => {
          $scope.watch = () => {
            if (!$scope.data.institution) {
              delete $scope.data.role;
            }
          };

          $scope.data = {};

          $scope.ok = () => {
            $scope.$close($scope.data);
          };

          $scope.cancel = () => {
            $scope.$dismiss();
          };
        },
      ],
    }).then((r) => {
      if (r) {
        this.data.external = this.data.external || [];
        this.data.external.push(r);
      }
    });
  };
}

DissertationsEditController.$inject = [
  "$state",
  "$scope",
  "$translate",
  "UIService",
  "AuthenticationService",
  "DissertationService",
  "PersonService",
];
