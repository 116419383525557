import React from "react";
import { Box } from "@mui/material";
export default function Item({ image, onClick }) {
    return (<Box onClick={onClick} sx={{
            width: 1,
            height: 1,
            background: image
                ? `url(${image}) center center no-repeat`
                : 'url(/assets/images/default.jpg) center center no-repeat',
            backgroundSize: 'contain',
            bgcolor: 'black',
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center"
        }}>
        </Box>);
}
