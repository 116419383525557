export default class JobsEditController {
  constructor($scope, $state, JobService, UIService, $translate, FileUploader) {
    this.auxDuration = true;
    this.$translate = $translate;
    this.FileUploader = FileUploader;
    this.uploader = new FileUploader({
      url: `/api/assets/upload/news`,
      queueLimit: 1,
    });

    this.uploader.onSuccessItem = (item, response) => {
      // Save in DB
      this.data.image = {
        container: "news",
        year: moment().year().toString(),
        month: ("00" + (moment().month() + 1)).slice(-2),
        cover: response.result.name,
      };
    };

    $scope.$watch(() => {
      return localStorage.getItem("lang")
    }, (val) => {
      this.lang = val;
    }, true);

    this.options = {
      format: "DD/MM/YYYY",
      minDate: moment(),
      maxDate: moment().add(2, "month"),
    }

    this.$state = $state;
    this.JobService = JobService;
    this.UI = UIService;

    this.loadData();
  }

  loadData = () => {
    // List of suggested entities
    this.JobService.suggestions().then(e => {
      this.entities = e;
      // Types of jobs
      this.JobService.type().then(r => {
        this.types = r;
        this.JobService.get(this.$state.params.id).then(r => {
          let entity = this.entities.find(entity => entity.name == r.entity.name);
          if (entity == null) {
            delete r.entity;
          }
          this.data = r;
          this.data.date = this.data.date || moment().toDate()
          this.loaded = true;
        });
      });
    });
  }

  save = () => {
    let messages = this.JobService.validate(this.data);
    if (messages.length > 0) {
      this.validate();
    } else {
      this.JobService.save(this.data).then(r => {
        // JUMP TO PROPOSAL
        this.UI.addToast(this.$translate.instant("TEXT_PROPOSAL_SAVED"));
        this.loadData();
      })
    }
  }

  validate = () => {
    let messages = this.JobService.validate(this.data);
    this.UI.showDialog({
      template: require('./../dialogs/message.html'),
      controller: ['$scope', $scope => {
        $scope.ok = () => {
          $scope.$close();
        }

        $scope.messages = messages;

        $scope.cancel = () => {
          $scope.$close();
        }
      }]
    });
  }

  newEntity = name => {
    this.UI.showDialog({
      template: require('./../dialogs/entity.html'),
      controller: ['$scope', $scope => {
        $scope.data = {
          name: name
        }
        $scope.ok = () => {
          $scope.$close($scope.data);
        };

        $scope.cancel = () => {
          $scope.$dismiss('cancel');
        };
      }]
    }).then(r => {
      this.entities = this.entities || [];
      this.entities.push(r);
      this.data.entity = this.entities[this.entities.length - 1];
      return r;
    });
  }

  
  upload = () => {
    this.UI.showDialog({
      size: "lg",
      template: require("../upload.dialog.html"),
      controller: [
        "$scope",
        ($scope) => {
          $scope.uploader = this.uploader;

          $scope.cancel = () => {
            $scope.$dismiss();
          };

          $scope.ok = () => {
            $scope.$close();
          };
        },
      ],
    }).then((r) => {
      console.log(this.data);
    });
  };
}

JobsEditController.$inject = ['$scope', '$state', 'JobService', 'UIService', '$translate', 'FileUploader'];
