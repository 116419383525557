import { Card, CardActions, Chip, ImageList, ImageListItem, Stack, Typography, useMediaQuery, useTheme, } from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
import ReactHtmlParser from "react-html-parser";
import moment from "moment";
import { useState, useRef, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import ViewDialog from "./dialog";
import Item from "./item";
export default function GridView({ $translate }) {
    const theme = useTheme();
    const [lang, setLang] = useState(localStorage.getItem("lang"));
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isSmall = useMediaQuery(theme.breakpoints.down("md"));
    const page = useRef(1);
    const [items, setItems] = useState([]);
    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState("");
    const [body, setBody] = useState("");
    const [image, setImage] = useState("");
    const loadImages = async (mount) => {
        // Simulate an API call to fetch images
        const from = moment().subtract((page.current - 1) * 30 + 30, "days");
        const to = moment().subtract((page.current - 1) * 30, "days");
        const newsCall = await fetch(`/api/news?filter={"order":"date DESC","where":{"date": {"between":["${from.toISOString()}","${to.toISOString()}"]}}}`);
        const jobsCall = await fetch(`/api/jobs?filter={"order":"date DESC","where":{"date": {"between":["${from.toISOString()}","${to.toISOString()}"]}}}`);
        const projectsCall = await fetch(`/api/projects?filter={"order":"creationDate DESC","where":{"creationDate": {"between":["${from.toISOString()}","${to.toISOString()}"]}}}`);
        if (mount) {
            let news = await newsCall.json();
            let jobs = await jobsCall.json();
            let projects = await projectsCall.json();
            news = news.map((x) => {
                x.label = "TEXT_NEWS";
                x.isNews = true;
                return x;
            });
            jobs = jobs.map((x) => {
                return {
                    isNews: false,
                    type: x.type.title,
                    title: x.title,
                    body: x.description,
                    summary: x.description,
                    date: x.dateStart,
                    label: "TEXT_JOB_OFFERS",
                    image: x.image ? x.image : null,
                };
            });
            projects = projects.map((x) => {
                return {
                    isNews: true,
                    title: x.title,
                    body: x.description,
                    summary: x.description,
                    date: x.beginDate,
                    label: "TEXT_PROJECTS",
                    image: x.image ? x.image : null,
                };
            });
            const arr = [...news, ...jobs, ...projects];
            if (arr.length > 0) {
                arr.sort((a, b) => moment(b.date).unix() - moment(a.date).unix());
                setItems((prevImages) => [...prevImages, ...arr]);
                page.current += 1;
            }
        }
        else {
            let news = await newsCall.json();
            let jobs = await jobsCall.json();
            let projects = await projectsCall.json();
            news = news.map((x) => {
                x.label = "TEXT_NEWS";
                x.isNews = true;
                return x;
            });
            jobs = jobs.map((x) => {
                return {
                    isNews: false,
                    type: x.type.title,
                    title: x.title,
                    body: x.description,
                    summary: x.description,
                    date: x.dateStart,
                    label: "TEXT_JOB_OFFERS",
                    image: x.image ? x.image : null,
                };
            });
            projects = projects.map((x) => {
                return {
                    isNews: true,
                    title: x.title,
                    body: x.description,
                    summary: x.description,
                    date: x.beginDate,
                    label: "TEXT_PROJECTS",
                    image: x.image ? x.image : null,
                };
            });
            const arr = [...news, ...jobs, ...projects];
            if (arr.length > 0) {
                arr.sort((a, b) => moment(b.date).unix() - moment(a.date).unix());
                setItems((prevImages) => [...prevImages, ...arr]);
                page.current += 1;
            }
        }
    };
    useEffect(() => {
        const handleStorageChange = (event) => {
            setLang(localStorage.getItem("lang"));
        };
        // Load initial images when the component mounts
        loadImages(true);
        const intervalId = setInterval(handleStorageChange, 500);
        // Cleanup function to clear the interval when the component is unmounted
        return () => {
            clearInterval(intervalId);
        };
    }, []);
    return (<Box sx={{
            background: "black",
        }}>
      <ViewDialog open={open} onClose={() => {
            setOpen(false);
        }} title={title} image={image} body={body}></ViewDialog>
      <Box id="pSc" sx={{
            flexGrow: 1,
            m: "0 auto",
            pt: "32px",
            width: isSmall ? 1 : "520px",
            alignContent: "center",
        }}>
        <InfiniteScroll dataLength={items.length} scrollableTarget="scrollableDiv" next={() => loadImages(false)} loader={<h4></h4>} hasMore={true}>
          <ImageList variant="quilted" cols={1} gap={32}>
            {items.map((item, index) => (<ImageListItem key={index} {...{ xs: 6, sm: 6, md: 4, lg: 3 }} sx={{ aspectRatio: 1 }} rows={1} cols={1}>
                <Card sx={{ height: 1, width: 1 }}>
                    <Stack direction="column" alignItems='center' justifyContent='center' sx={{ height: 1, width: 1 }}>
                      <Item image={item.image
                ? `/api/assets/download/${item.image.container}/${item.image.year}/${item.image.month}/${item.image.cover}`
                : ""} onClick={() => {
                setTitle(item.title.pt);
                setBody(item.summary.pt);
                setImage(item.image
                    ? `/api/assets/download/${item.image.container}/${item.image.year}/${item.image.month}/${item.image.cover}`
                    : "");
                setOpen(true);
            }}></Item>
                      <Stack direction="row" justifyContent="space-between" alignItems="flex-start" gap={2} sx={{
                width: 1,
                p: 2,
                position: "below",
                bottom: 0,
                color: "white",
                background: "rgba(0, 0, 0, 0.8)",
            }}>
                        <Box sx={{
                width: 1,
                flexShrink: 1,
                alignSelf: { xs: "flex-start", sm: "center" },
            }}>
                          <Stack sx={{ width: 1 }} direction={isMobile ? "column-reverse" : "row"} justifyContent="space-between" alignItems="flex-start" flexWrap="wrap">
                            <Typography fontWeight="bold" sx={{ mb: 0.5 }}>
                              {item.title[lang]}
                            </Typography>
                          </Stack>
                          <Stack direction="row" flexWrap="wrap" sx={{ mb: 0.75 }}>
                            <Chip label={`${$translate.instant(item.label)}${item.isNews ? "" : ` - ${item.type[lang]}`}`} color="primary" size="small"/>
                            &nbsp; &nbsp;
                            <Typography>
                              {moment(item.date).format("DD/MM/YYYY")}
                            </Typography>
                          </Stack>
                          <Typography variant="body2" sx={{
                display: "-webkit-box",
                overflow: "hidden",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2,
            }}>
                            {ReactHtmlParser(item.summary[lang])}
                          </Typography>
                        </Box>
                      </Stack>
                    </Stack>
                  <CardActions></CardActions>
                </Card>
              </ImageListItem>))}
          </ImageList>
        </InfiniteScroll>
      </Box>
    </Box>);
}
