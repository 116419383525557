export default class NewsInsertController {
  constructor(
    $scope,
    $state,
    NewsService,
    UIService,
    $translate,
    FileUploader
  ) {
    this.auxDuration = true;
    this.$translate = $translate;

    this.data = {
      source: "",
      image: null,
      expires: moment().add(1, "month").toDate(),
      date: moment().toDate(),
    };

    this.uploader = new FileUploader({
      url: `/api/assets/upload/news`,
      queueLimit: 1,
    });

    this.uploader.onSuccessItem = (item, response) => {
      // Save in DB
      this.data.image = {
        container: "news",
        year: moment().year().toString(),
        month: ("00" + (moment().month() + 1)).slice(-2),
        cover: response.result.name,
      };
    };

    $scope.$watch(
      () => {
        return localStorage.getItem("lang");
      },
      (val) => {
        this.lang = val;
      },
      true
    );

    this.options = {
      format: "DD/MM/YYYY",
      minDate: moment().add(1, "month"),
      maxDate: moment().add(2, "month"),
    };

    this.startOp = {
      format: "DD/MM/YYYY",
      minDate: moment(),
    };

    this.endOp = {
      format: "DD/MM/YYYY",
      minDate: this.data ? this.data.dateStart : moment(),
    };

    $scope.$watch(
      () => {
        return this.auxDuration;
      },
      (val) => {
        if (!val) {
          $scope.$watch(
            () => {
              return this.data.dateStart;
            },
            (val) => {
              if (val) {
                if (moment(this.data.dateEnd).isBefore(val)) {
                  delete this.data.dateEnd;
                }
                this.endOp = {
                  format: "DD/MM/YYYY",
                  minDate: val || moment(),
                };
              }
            }
          );
        }
      }
    );

    this.$state = $state;
    this.NewsService = NewsService;
    this.UI = UIService;

    this.loadData();
  }

  loadData = () => {
    this.loaded = true;
  };

  save = () => {
    let messages = this.NewsService.validate(this.data, this.auxDuration);
    if (messages.length > 0) {
      this.validate();
    } else {
      this.NewsService.save(this.data).then((r) => {
        // JUMP TO PROPOSAL
        this.$state.go("app.news.details", {
          id: r.id,
        });
        this.UI.addToast(this.$translate.instant("TEXT_NEWS_SAVED"));
      });
    }
  };

  validate = () => {
    let messages = this.NewsService.validate(this.data, this.auxDuration);
    this.UI.showDialog({
      template: require("./message.html"),
      controller: [
        "$scope",
        ($scope) => {
          $scope.ok = () => {
            $scope.$close();
          };

          $scope.messages = messages;

          $scope.cancel = () => {
            $scope.$close();
          };
        },
      ],
    });
  };

  upload = () => {
    this.UI.showDialog({
      size: "lg",
      template: require("../upload.dialog.html"),
      controller: [
        "$scope",
        ($scope) => {
          $scope.uploader = this.uploader;

          $scope.cancel = () => {
            $scope.$dismiss();
          };

          $scope.ok = () => {
            $scope.$close();
          };
        },
      ],
    }).then((r) => {
      console.log(this.data);
    });
  };
}

NewsInsertController.$inject = [
  "$scope",
  "$state",
  "NewsService",
  "UIService",
  "$translate",
  "FileUploader",
];
