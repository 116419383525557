export default class NewsDetailscontroller {
    constructor($scope, $state, $translate, NewsService, UIService) {
        this.$state = $state;
        this.$translate = $translate;
        this.NewsService = NewsService;
        this.UI = UIService;

        $scope.$watch(() => {
            return localStorage.getItem("lang")
        }, (val) => {
            this.lang = val;
        }, true);

        this.loadData();
    }

    loadData = () => {
        this.loaded = false;
        this.NewsService.get(this.$state.params.id).then(r => {
            this.data = r;
            console.log(this.data);
            this.loaded = true;
        }).catch(e => {
            this.$state.go('app.news.own');
        });
    }
    

    delete = () => {
        this.UI.showConfirm(this.$translate.instant("TEXT_CONFIRM_DELETE")).then(r => {
            this.NewsService.delete(this.data).then(() => {
                this.$state.go('app.news.own');
                this.UI.addToast(this.$translate.instant("TEXT_DELETED"));
            }).catch(e => {
                this.$state.go('app.news.own');
                this.UI.addToast(this.$translate.instant("TEXT_FAILED_DELETED"));
            });
        });
    }
}

NewsDetailscontroller.$inject = ['$scope', '$state', '$translate', 'NewsService', 'UIService'];