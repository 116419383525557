import React, { useEffect, useState } from "react";
import { ThemeProvider, Stack, Button, Typography, Grid, Card, Box, CircularProgress, } from "@mui/material";
import { theme } from "../utils/theme";
import SnackbarComponent from "../utils/SnackBar";
const ProjectsOwn = ({ $state, ProjectService, $translate }) => {
    const [lang, setLang] = useState(localStorage.getItem("lang"));
    const [ownProjects, setOwnProjects] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    useEffect(() => {
        if (ownProjects.length === 0) {
            loadOwnProjects();
        }
        // Handle Language
        const handleStorageChange = (event) => {
            setLang(localStorage.getItem("lang"));
        };
        const intervalId = setInterval(handleStorageChange, 500);
        return () => {
            clearInterval(intervalId);
        };
    }, []);
    const closeSnackbar = () => {
        setOpenSnackbar(false);
    };
    const loadOwnProjects = async () => {
        try {
            const projects = await ProjectService.getOwnProjects();
            setOwnProjects(projects);
            setLoading(false);
        }
        catch (error) {
            console.error("Erro ao obter projetos:", error);
            setSnackMessage("Erro ao obter projetos");
            setOpenSnackbar(true);
            setLoading(false);
        }
    };
    const AddProject = () => {
        $state.go("app.projects.insert");
    };
    const handleProjectClick = (project) => {
        $state.go("app.projects.details", { id: project.id });
    };
    return (<ThemeProvider theme={theme}>
      {loading ? (<Box sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
            }}>
          <CircularProgress color="primary"/>
        </Box>) : (<Stack>
          <Stack direction="column" spacing={2}>
            <Stack justifyContent="flex-start" alignItems="flex-start" spacing={2}>
              <Typography variant="h5">{`${$translate.instant("TEXT_PROJECTS_OWN")}`}</Typography>

              <Button variant="contained" onClick={AddProject}>
                {`${$translate.instant("BUTTON_ADD")}`}
              </Button>
            </Stack>
          </Stack>

          <Grid container spacing={2} sx={{ marginRight: "10", marginTop: 1 }}>
            {ownProjects.length !== 0 ? (ownProjects.map((project, index) => (<Grid item xs={12} sm={6} md={4} key={index}>
                  <Card sx={{ padding: "10px", borderRadius: 3 }} onClick={() => handleProjectClick(project)}>
                    <Stack spacing={2}>
                      <div>
                        <Typography variant="h6">
                          {project.title.pt !== ""
                    ? project.title.pt
                    : project.title.en}
                        </Typography>
                        <Typography variant="subtitle1">
                          {`${$translate.instant("TEXT_INVESTIGATOR")}`}:{" "}
                          {project.investigator}
                        </Typography>
                      </div>
                      <Stack justifyContent="flex-end" alignItems="flex-end">
                        <Typography variant="body2">
                          {`${$translate.instant("TEXT_BEGIN")}`}{" "}
                          {project.beginDate.slice(0, 10)}
                        </Typography>
                        <Typography variant="body2">
                          {`${$translate.instant("TEXT_END")}`}{" "}
                          {project.endDate.slice(0, 10)}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Card>
                </Grid>))) : (<Stack sx={{ marginLeft: 2, marginTop: 2 }}>
                <Typography variant="subtitle1">
                  {$translate.instant("TEXT_NO_PROJECTS")}
                </Typography>
              </Stack>)}
          </Grid>
        </Stack>)}
      <SnackbarComponent message={snackMessage} open={openSnackbar} severity={"normal"} onClose={closeSnackbar}/>
    </ThemeProvider>);
};
export default ProjectsOwn;
