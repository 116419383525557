import React, { useEffect, useState, useMemo } from "react";
import { ThemeProvider, Card, Stack, CircularProgress, Tooltip, Typography, IconButton, } from "@mui/material";
import { theme } from "../utils/theme";
import "./../utils/utils.css";
import SnackbarComponent from "../utils/SnackBar";
import SimpleModal from "../utils/SimpleModal";
import { MaterialReactTable, useMaterialReactTable, } from "material-react-table";
import Icon from "@mdi/react";
import { mdiPlus } from "@mdi/js";
const ChargesList = ({ $state, ChargeService, $translate }) => {
    const [charges, setCharges] = useState([]);
    const [toDelete, setToDelete] = useState("");
    const [loading, setLoading] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [lang, setLang] = useState(localStorage.getItem("lang"));
    useEffect(() => {
        // Load Projects
        const fetchProjects = async () => {
            try {
                const resource = await ChargeService.getCharges();
                setCharges(resource);
                setLoading(false);
            }
            catch (error) {
                console.error("Erro ao obter projetos:", error.message);
                setSnackMessage("Erro ao obter projetos");
                setOpenSnackbar(true);
                setLoading(false);
            }
        };
        if (charges.length === 0) {
            fetchProjects();
        }
        // Handle Language
        const handleStorageChange = (event) => {
            setLang(localStorage.getItem("lang"));
        };
        const intervalId = setInterval(handleStorageChange, 500);
        return () => {
            clearInterval(intervalId);
        };
    }, []);
    const closeSnackbar = () => {
        setOpenSnackbar(false);
    };
    const handleModal = (id) => {
        setToDelete(id);
        setModalOpen(true);
        setModalMessage("Pretende eliminar o carregamento?");
    };
    // if modal OK (result true) delete carregamento
    const handleModalClose = async (result) => {
        setModalOpen(false);
        if (result) {
            try {
                await ChargeService.deleteChargeById(toDelete);
                // update charges list (update table)
                setCharges((prevCharges) => prevCharges.filter((charge) => charge.id !== toDelete));
                setSnackMessage("Carregamento Eliminado");
                setOpenSnackbar(true);
            }
            catch (error) {
                console.error("Erro ao eliminar projeto:", error);
                setSnackMessage("Erro ao eliminar utilizador");
                setOpenSnackbar(true);
            }
        }
    };
    const CostumTable = ({ data }) => {
        //should be memoized or stable
        const columns = useMemo(() => [
            {
                accessorKey: "createdBy.name",
                header: "Utilizador",
                size: 150,
            },
            {
                accessorKey: "date",
                header: "Data",
                size: 50,
                Cell: ({ cell }) => `${cell.getValue().toString().slice(0, 10)}`,
            },
            {
                accessorKey: "power",
                header: "Corrente (A)",
                size: 50,
            },
            {
                accessorKey: "chargingMinutes",
                header: "Tempo de Carregamento (minutos)",
                size: 50,
            },
        ], []);
        const table = useMaterialReactTable({
            columns,
            data,
            enableFullScreenToggle: false, // remove fullscreen button
            /*enableRowActions: true, // Action Row
            positionActionsColumn: "last", // Action Row last position
            displayColumnDefOptions: {
              "mrt-row-actions": {
                header: "Ações", //change header text
                size: 80, //make actions column wider
              },
            },
             renderRowActionMenuItems: ({ row }) => [
              <Tooltip title="Editar Carregamento" key="edit" placement="left">
                <MenuItem
                  onClick={() =>
                    $state.go("app.charges.update", {
                      id: row.original.id,
                    })
                  }
                  key="edit"
                >
                  <Icon path={mdiPencil} size={1} />
                </MenuItem>
              </Tooltip>,
            ], */
        });
        return <MaterialReactTable table={table}/>;
    };
    if (loading) {
        return (<ThemeProvider theme={theme}>
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
          <CircularProgress color="primary"/>
        </Stack>
      </ThemeProvider>);
    }
    return (<ThemeProvider theme={theme}>
      <Card sx={{ padding: "10px", borderRadius: 3, marginBottom: 2 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={2} sx={{ marginBottom: 2 }}>
          <Typography variant="h6">Carregamentos</Typography>
          <Tooltip title="Adicionar Carregamentos">
            <IconButton aria-label="add" onClick={() => $state.go("app.charges.insert")}>
              <Icon path={mdiPlus} size={1}/>
            </IconButton>
          </Tooltip>
        </Stack>
        <CostumTable data={charges}/>
      </Card>

      <SimpleModal open={modalOpen} onClose={handleModalClose} message={modalMessage}/>

      <SnackbarComponent message={snackMessage} open={openSnackbar} severity={"normal"} onClose={closeSnackbar}/>
    </ThemeProvider>);
};
export default ChargesList;
