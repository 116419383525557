import React, { useEffect, useState } from "react";
import Form from "../utils/Form";
import { CircularProgress, Stack, ThemeProvider, Typography, } from "@mui/material";
import { theme } from "../utils/theme";
import SnackbarComponent from "../utils/SnackBar";
const ProjectsUpdate = ({ $state, ProjectService, $translate }) => {
    const [project, setProject] = useState();
    const [loading, setLoading] = useState(true);
    const [lang, setLang] = useState(localStorage.getItem("lang"));
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [user, setUser] = useState({
        id: "",
        name: "",
        email: "",
    });
    useEffect(() => {
        // Get project details
        const fetchProjects = async () => {
            try {
                const resource = await ProjectService.getProject($state.params.id);
                setProject(resource);
                setLoading(false);
            }
            catch (error) {
                console.error("Erro ao carregar projeto:", error.message);
                setSnackMessage("Erro ao carregar projeto");
                setOpenSnackbar(true);
                setLoading(false);
            }
        };
        if (loading) {
            fetchProjects();
        }
        const handleStorageChange = () => {
            setLang(localStorage.getItem("lang"));
        };
        const intervalId = setInterval(handleStorageChange, 500);
        return () => {
            clearInterval(intervalId);
        };
    }, [$state.params.id]);
    const closeSnackbar = () => {
        setOpenSnackbar(false);
    };
    if (loading) {
        return (<ThemeProvider theme={theme}>
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
          <CircularProgress color="primary"/>
        </Stack>
      </ThemeProvider>);
    }
    return (<ThemeProvider theme={theme}>
      <Stack spacing={2}>
        <Typography variant="h6">
          {$translate.instant("TEXT_UPDATE_PROJECT")}
        </Typography>
        <Typography variant="body2">
          {$translate.instant("TEXT_MANDATORY_FIELDS")}
        </Typography>
        <Form $state={$state} projectData={project} ProjectService={ProjectService} $translate={$translate} type={"update"} userData={user}></Form>
      </Stack>

      <SnackbarComponent message={snackMessage} open={openSnackbar} severity={"normal"} onClose={closeSnackbar}/>
    </ThemeProvider>);
};
export default ProjectsUpdate;
