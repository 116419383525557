import React, { useEffect, useState } from "react";
import Form from "../utils/Form";
import { Box, CircularProgress, Stack, ThemeProvider, Typography, } from "@mui/material";
import { theme } from "../utils/theme";
import SnackbarComponent from "../utils/SnackBar";
const ProjectsInsert = ({ $state, ProjectService, $translate }) => {
    const [lang, setLang] = useState(localStorage.getItem("lang"));
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState({
        id: "",
        name: "",
        email: "",
    });
    const getUser = async () => {
        try {
            const userData = await ProjectService.getLoggedUser();
            setUser({
                id: userData.id,
                name: userData.username,
                email: userData.email,
            });
        }
        catch (error) {
            console.error("Erro ao obter utilizador:", error);
            setSnackMessage("Erro ao obter utilizador");
            setOpenSnackbar(true);
        }
        finally {
            setLoading(false); // Indicate that loading is complete
        }
    };
    const closeSnackbar = () => {
        setOpenSnackbar(false);
    };
    useEffect(() => {
        if (loading) {
            getUser();
        }
        // Handle Language
        const handleStorageChange = (event) => {
            setLang(localStorage.getItem("lang"));
        };
        const intervalId = setInterval(handleStorageChange, 500);
        return () => {
            clearInterval(intervalId);
        };
    }, []);
    if (loading) {
        return (<Box sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
            }}>
        <CircularProgress color="primary"/>
      </Box>);
    }
    return (<ThemeProvider theme={theme}>
      <Stack spacing={2}>
        <Typography variant="h6">
          {$translate.instant("TEXT_NEW_PROJECT")}
        </Typography>
        <Typography variant="body2">
          {$translate.instant("TEXT_MANDATORY_FIELDS")}
        </Typography>
        <Form $state={$state} ProjectService={ProjectService} $translate={$translate} type={"create"} userData={user}></Form>
      </Stack>
      <SnackbarComponent message={snackMessage} open={openSnackbar} severity={"normal"} onClose={closeSnackbar}/>
    </ThemeProvider>);
};
export default ProjectsInsert;
