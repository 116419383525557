import React, { useEffect, useState } from "react";
import { ThemeProvider, Card, Stack, Typography, Grid, TextField, Button, CircularProgress, } from "@mui/material";
import { theme } from "../utils/theme";
import "./../utils/utils.css";
import SnackbarComponent from "../utils/SnackBar";
const ProjectsList = ({ $state, ProjectService, $translate }) => {
    const [projects, setProjects] = useState([]);
    const [filteredProjects, setFilteredProjects] = useState([]);
    const [text, setText] = useState("");
    const [loading, setLoading] = useState(true);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [lang, setLang] = useState(localStorage.getItem("lang"));
    useEffect(() => {
        // Load Projects
        const fetchProjects = async () => {
            try {
                const resource = await ProjectService.getProjects();
                setProjects(resource);
                setFilteredProjects(resource);
                setLoading(false);
            }
            catch (error) {
                console.error("Erro ao obter projetos:", error.message);
                setSnackMessage("Erro ao obter projetos");
                setOpenSnackbar(true);
                setLoading(false);
            }
        };
        if (projects.length === 0) {
            fetchProjects();
        }
        // Handle Language
        const handleStorageChange = (event) => {
            setLang(localStorage.getItem("lang"));
        };
        const intervalId = setInterval(handleStorageChange, 500);
        return () => {
            clearInterval(intervalId);
        };
    }, []);
    const closeSnackbar = () => {
        setOpenSnackbar(false);
    };
    const handleSearch = (e) => {
        const filtered = projects.filter((project) => project.title.pt !== ""
            ? project.title.pt.toLowerCase().includes(text.toLowerCase())
            : project.title.en.toLowerCase().includes(text.toLowerCase()));
        setFilteredProjects(filtered);
    };
    const handleText = (e) => {
        setText(e.target.value);
    };
    const handleProjectClick = (project) => {
        $state.go("app.projects.details", { id: project.id });
    };
    if (loading) {
        return (<ThemeProvider theme={theme}>
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
          <CircularProgress color="primary"/>
        </Stack>
      </ThemeProvider>);
    }
    return (<ThemeProvider theme={theme}>
      <Card sx={{ padding: "10px", borderRadius: 3, marginBottom: 2 }}>
        <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={2}>
          <TextField fullWidth id="title" label={`${$translate.instant("TEXT_SEARCH_TITLE")}`} variant="standard" value={text} onChange={handleText}/>
          <Stack direction="row" spacing={2}>
            <Button component="label" id="search" role={undefined} variant="contained" onClick={handleSearch}>
              {`${$translate.instant("BUTTON_FILTER")}`}
            </Button>
            <Button component="label" role={undefined} variant="text" onClick={() => {
            setText("");
            setFilteredProjects(projects);
        }}>
              {`${$translate.instant("BUTTON_CLEAR")}`}
            </Button>
          </Stack>
        </Stack>
      </Card>
      <Grid container spacing={2} sx={{ marginRight: "10" }}>
        {filteredProjects.length !== 0 ? (filteredProjects.map((project, index) => (<Grid item xs={12} sm={6} md={4} key={index}>
              <Card sx={{ padding: "10px", borderRadius: 3 }} onClick={() => handleProjectClick(project)}>
                <Stack spacing={2}>
                  <div>
                    <Typography variant="h6">
                      {project.title.pt !== ""
                ? project.title.pt
                : project.title.en}
                    </Typography>
                    <Typography variant="subtitle1">
                      {`${$translate.instant("TEXT_INVESTIGATOR")}`}:{" "}
                      {project.investigator}
                    </Typography>
                  </div>
                  <Stack justifyContent="flex-end" alignItems="flex-end">
                    <Typography variant="body2">
                      {`${$translate.instant("TEXT_BEGIN")}`}{" "}
                      {project.beginDate.slice(0, 10)}
                    </Typography>
                    <Typography variant="body2">
                      {`${$translate.instant("TEXT_END")}`}{" "}
                      {project.endDate.slice(0, 10)}
                    </Typography>
                  </Stack>
                </Stack>
              </Card>
            </Grid>))) : (<Stack sx={{ marginLeft: 2, marginTop: 2 }}>
            <Typography variant="subtitle1">
              {$translate.instant("TEXT_NO_PROJECTS")}
            </Typography>
          </Stack>)}
      </Grid>
      <SnackbarComponent message={snackMessage} open={openSnackbar} severity={"normal"} onClose={closeSnackbar}/>
    </ThemeProvider>);
};
export default ProjectsList;
