export default class PublicDissertationDetailsController {
  constructor($state, DissertationService) {
    this.$state = $state;
    this.DissertationService = DissertationService;
    this.lang = "en";
    this.loadData();
  }

  loadData = () => {
    this.loaded = false;
    this.DissertationService.get(this.$state.params.id).then(r => {
      console.log(r);
      this.data = r;
      this.loaded = true;
    });
  };

}

PublicDissertationDetailsController.$inject = ['$state', 'DissertationService'];
