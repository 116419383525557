import React, { useEffect, useState } from "react";
import { TextField, ThemeProvider, Card, Fab, Typography, Box, Grid, MenuItem, Select, } from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { theme } from "./theme";
import Icon from "@mdi/react";
import { mdiContentSave } from "@mdi/js";
import "./../utils/utils.css";
import { useFormik } from "formik";
import * as yup from "yup";
import SnackbarComponent from "./SnackBar";
import moment from "moment";
const Form = ({ $state, ChargeInfo, ChargeService, $translate, type, userData }) => {
    const [power, setPower] = React.useState(10);
    const [lang, setLang] = useState(localStorage.getItem("lang"));
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const validationSchema = yup.object().shape({
        power: yup.number().min(0).required(""),
        chargingMinutes: yup.number().min(1).required(),
        date: yup.date().required(),
    });
    const formik = useFormik({
        initialValues: {
            id: type === "create" ? "" : ChargeInfo.id,
            date: type === "create" ? moment() : moment(ChargeInfo.date),
            power: type === "create" ? 10 : ChargeInfo.power,
            chargingMinutes: type === "create" ? 0 : ChargeInfo.chargingMinutes,
            createdBy: {
                id: type === "create" ? "" : ChargeInfo.createdBy.id,
                name: type === "create" ? "" : ChargeInfo.createdBy.name,
                email: type === "create" ? "" : ChargeInfo.createdBy.email,
            },
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            //console.log(values);
            //submit();
            submit(values);
        },
    });
    // show message received in the snackbar -> go to projects list
    const goTo = (message) => {
        setSnackMessage(message);
        setOpenSnackbar(true);
        setTimeout(() => $state.go("app.charges.list"), 2000);
    };
    const closeSnackbar = () => {
        setOpenSnackbar(false);
    };
    useEffect(() => {
        console.log(userData);
        if (type === "update") {
            setPower(ChargeInfo.power);
        }
        // Handle language
        const handleStorageChange = () => {
            setLang(localStorage.getItem("lang"));
        };
        const intervalId = setInterval(handleStorageChange, 500);
        return () => {
            clearInterval(intervalId);
        };
    }, []);
    const submit = async (chargeData) => {
        try {
            if (type === "create") {
                chargeData.createdBy = userData;
                delete chargeData.id;
                await ChargeService.createCharge(chargeData);
                goTo("Carregamento adicionado!");
            }
            else {
                await ChargeService.updateCharge(chargeData);
                goTo("Carregamento editado com sucesso!");
            }
        }
        catch (error) {
            if (type === "create") {
                console.error("Erro ao criar projeto:", error);
                setSnackMessage("Erro ao criar projeto");
                setOpenSnackbar(true);
            }
            else {
                console.error("Erro ao editar projeto:", error);
                setSnackMessage("Erro ao editar projeto");
                setOpenSnackbar(true);
            }
        }
    };
    const handleSelect = (event) => {
        formik.setFieldValue("power", event.target.value);
        setPower(event.target.value);
    };
    return (<ThemeProvider theme={theme}>
      <form onSubmit={formik.handleSubmit}>
        <Card sx={{ padding: "10px", borderRadius: 3 }}>
          <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item xs={3}>
              <Typography>Data do carregamento </Typography>
            </Grid>
            <Grid item xs={9}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker label="Data" sx={{ marginBottom: 10, width: "10%" }} value={formik.values.date} onChange={(value) => formik.setFieldValue("date", moment(value))} slotProps={{
            textField: {
                error: Boolean(formik.errors.date),
            },
        }}/>
                </DemoContainer>
              </LocalizationProvider>
            </Grid>

            <Grid item xs={3}>
              <Typography>Duração do carregamento </Typography>
              <Typography variant="caption" display="block">
                Tempo de carregamento efetivo, em minutos.
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <TextField type="number" id="chargingMinutes" name="chargingMinutes" variant="outlined" required={true} value={formik.values.chargingMinutes} onChange={formik.handleChange} error={Boolean(formik.errors.chargingMinutes)} sx={{ width: 200 }}/>
            </Grid>

            <Grid item xs={3}>
              <Typography>Corrente de carregamento </Typography>
            </Grid>
            <Grid item xs={9}>
              <Select id="demo-simple-select" value={power} label="Age" onChange={handleSelect} required={true} sx={{ width: 200 }}>
                <MenuItem value={10}>10 A</MenuItem>
                <MenuItem value={16}>16 A</MenuItem>
                <MenuItem value={32}>32 A</MenuItem>
              </Select>
            </Grid>
          </Grid>
        </Card>
      </form>

      <Box className="fabButtonPosition">
        <Fab color="primary" aria-label="add" sx={{ position: "absolute", backgroundColor: "#db4437" }} onClick={() => formik.handleSubmit()}>
          <Icon path={mdiContentSave} size={1}/>
        </Fab>
      </Box>

      <SnackbarComponent message={snackMessage} open={openSnackbar} severity={"normal"} onClose={closeSnackbar}/>
    </ThemeProvider>);
};
export default Form;
