export default class AccessFormController {
  constructor($translate, $state, AuthenticationService, AccessService, UIService, PersonService) {
    this.$state = $state;
    this.$translate = $translate;
    this.AccessService = AccessService;
    this.UI = UIService;
    this.PersonService = PersonService;

    let user = AuthenticationService.getUser();

    this.commonSpace = false;

    this.data = {
      applicantNumber: user.id || undefined,
      applicantName: user.preferred || user.username,
      applicantMail: user.email,
      responsibleEntityMail: "",
      dateStart: undefined,
      dateEnd: undefined,
      reason: "",
      observations: "",
      state: 0,
      requestType: "",
      spaces: {
        general: [],
        entities: [],
        labs: [],
      },
    };

    this.usableSpaces = this.AccessService.getUsableSpaces();
    this.entitySpaces = this.AccessService.getEntitySpaces();

    //Type of request
    this.type = ["Aluno de Doutoramento", "Aluno de Dissertação & Mestrado", "Investigador IT Coimbra", "Investigador ISR Coimbra", "Investigador INESC Coimbra", "Investigador CEMMPRE"];

    // Find information for responsable
    this.loading = true;
    this.availableResponsables = [];
    this.auxResponsavel = undefined;
    this.loadData();
  }

  // Find Docentes, Não Docentes for responsable choosing
  loadData = () => {
    let wait = this.UI.showWaiting();
    this.PersonService.teachers()
      .then((teachers) => {
        this.availableResponsables = teachers;
        this.PersonService.staffs()
          .then((staffs) => {
            wait.close();
            this.availableResponsables = this.availableResponsables.concat(staffs);
          })
          .catch((error) => {
            console.log(error);
            wait.close();
            let errorMessage = this.$translate.instant("TEXT_FORM_ACCESS_NAO_DOCENTES_ERROR");
            alert = this.UI.showAlert(errorMessage);
            alert
              .finally(() => {
                // Do nothing right now.
              })
              .catch(() => {});
          });
      })
      .catch((error) => {
        console.log(error);
        wait.close();
        let errorMessage = this.$translate.instant("TEXT_FORM_ACCESS_DOCENTES_ERROR");
        alert = this.UI.showAlert(errorMessage);
        alert
          .finally(() => {
            // Do nothing right now.
          })
          .catch(() => {});
      });
  };

  save = () => {
    if (this.form.form.$invalid) {
      this.UI.addToast(this.$translate.instant("TEXT_REQUIRED_FIELDS"));
      return;
    } else {
      this.addRequest();
    }
  };

  hasLabsSelected = () => {
    return this.usableSpaces.filter((r) => r.selected).length > 0;
  };

  addRequest = () => {
    // Check if Responsable is filled

    if (this.hasLabsSelected() && (!this.auxResponsavel || !this.auxResponsavel.email)) {
      this.UI.addToast(this.$translate.instant("TEXT_FORM_ACCESS_MISSING_RESPONSABLE"));
      return;
    }
    if (this.hasLabsSelected()) {
      // Save responsibleEntityMail
      this.data.responsibleEntityMail = this.auxResponsavel.email;
    }

    //Save Spaces
    this.data.spaces.general = [];
    this.data.spaces.entities = [];
    this.data.spaces.labs = [];
    // General
    if (this.commonSpace) {
      this.data.spaces.general.push("Entrada Piso 2");
      this.data.spaces.general.push("Entrada Piso 6");
    }
    // Entidades
    this.entitySpaces.forEach((r) => {
      if (r.selected) {
        this.data.spaces.entities.push(r.designacao);
      }
    });
    // Laboratories
    this.usableSpaces.forEach((r) => {
      if (r.selected) {
        this.data.spaces.labs.push(r.designacao);
      }
    });

    let allSpaces = [...this.data.spaces.general, ...this.data.spaces.entities, ...this.data.spaces.labs];
    // Check if no spaces were selected
    if (allSpaces.length === 0) {
      this.UI.addToast(this.$translate.instant("TEXT_FORM_ACCESS_MISSING_SPACES"));
      return;
    }
    //Save data
    this.AccessService.save(this.data)
      .then(() => {
        this.UI.addToast(this.$translate.instant("TEXT_FORM_REQUEST_SENT"));
        this.$state.go("app.contacts.list");
      })
      .catch((error) => {
        console.log(error);
        this.UI.addToast(this.$translate.instant("TEXT_FORM_ACCESS_ERROR"));
        return;
      });
  };
}
AccessFormController.$inject = ["$translate", "$state", "AuthenticationService", "AccessService", "UIService", "PersonService"];
